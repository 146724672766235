import { DAY_OF_WEEK, LABEL } from '@/config/Constant';

export default {
  loadLineChartSuccess(state, { type, responses }) {
    responses.forEach((response) => {
      const chart = response.chart;
      console.log(chart);
      let data = response.data;
      if (['WEEK', 'MONTH', 'YEAR'].includes(type)) {
        const temp = [...data];
        data = {
          preventTotal: temp[0].preventTotal,
          total: temp.reduce((total, d) => total + d.value, 0),
          data: temp.map((d) => d.value),
          label: temp.map((d) => d[type === 'YEAR' ? 'month' : 'date']),
        };
      } else {
        data = data[0];
        data.data = Object.keys(data).reduce((array, item) => {
          if (!['percent', 'total'].includes(item)) {
            array.push(data[item]);
          }
          return array;
        }, []);
        data.label = ['0', '4', '8', '12', '16', '20', '24'];
      }
      state[chart].label = data.label;
      state[chart].data = data.data;
      state[chart].preventTotal = data.preventTotal;
      state[chart].total = data.total;
      state[chart].type = 'trending-up';
      if (data.preventTotal && data.total / data.preventTotal < 1) {
        state[chart].type = 'trending-down';
      }
    });
  },

  loadUserRegisterSuccess(state, { type, data }) {
    console.log(data);
    const temp = [...data];
    if (type === 'WEEK') {
      state.userRegister.label = DAY_OF_WEEK.slice(0, temp.length);
    } else {
      state.userRegister.label = temp.map(
        (d) => LABEL[type] + ' ' + d[type === 'YEAR' ? 'month' : 'date']
      );
    }
    state.userRegister.user = temp.map((d) => d.value);
    state.userRegister.ekyc = temp.map((d) => d.ekyc);
    state.userRegister.preventTotal = temp[0].preventTotal;
    state.userRegister.total = temp.reduce((total, d) => total + d.value, 0);
    state.userRegister.type = 'trending-up';
    if (
      state.userRegister.preventTotal &&
      state.userRegister.total / state.userRegister.preventTotal < 1
    ) {
      state.userRegister.type = 'trending-down';
    }
    state.registerLoading = false;
  },

  loadOverviewSuccess(state, overview) {
    state.overview = overview;
  },

  twitterOverviewBegin(state) {
    state.twLoading = true;
  },

  twitterOverviewSuccess(state, data) {
    state.twLoading = false;
    state.twitterOverviewData = data;
  },

  twitterOverviewErr(state, err) {
    state.twLoading = false;
    state.error = err;
  },
};
