import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {FORM_MODE} from '@/util/common-constant';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {showConfirm} from '@/util/confirm';

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    articles: {},
    api: ConstantAPI.articles,
    visible: false,
});

const actions = {
    async getSearch({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.SEARCH);
            return commit('getAllSuccess', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async getAll({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.ALL);
            return commit('getAll', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async preCreate({commit}) {
        commit('preCreate');
    },
    async preUpdate({commit, state}, row) {
        const response = await DataService.get(
            state.api.SEARCH.url + '/' + row.id
        );
        commit('setFormValue', {
            articles: response.data,
            formMode: FORM_MODE.UPDATE,
        });
    },
    async preView({commit, state}, row) {
        const response = await DataService.get(
            state.api.SEARCH.url + '/' + row.id
        );
        commit('setFormValue', {
            articles: response.data,
            formMode: FORM_MODE.VIEW,
        });
    },
    async create({commit, state}, payload) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(
                state.api.CREATE,
                payload,
                null,
            );
            let success = false;
            checkResponse(response, () => {
                success = true;
                notification.success({
                    message: 'Thông báo',
                    description: 'Thêm mới Bài viết thành công',
                    duration: 4,
                });
            });
            return success;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async update({commit, state}, payload) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(
                state.api.UPDATE,
                {
                    ...payload,
                    id: state.articles.id,
                },
                null,
            );
            let success = false;
            checkResponse(response, () => {
                success = true;
                notification.success({
                    message: 'Thông báo',
                    description: 'Cập nhật Bài viết thành công',
                    duration: 4,
                });
            });
            return success;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },

    async delete({state}, rows) {
        try {
            if (!rows.length) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không có bản ghi được chọn',
                    duration: 4,
                });
                return false;
            }
            if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
                const response = await DataService.delete(
                    state.api.DELETE.url + '/' + rows.map((e) => e.id).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Xóa Bài viết thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async opTop({state}, row) {
        try {
            if (await showConfirm('Bạn có chắc chắn muốn cho bài viết lên top không?')) {
                const response = await DataService.patch(
                    state.api.TOP.url + '/' + row.id
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Lên top thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async approval({state}, rows) {
        try {
            if (!rows.length) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không có bản ghi được chọn',
                    duration: 4,
                });
                return false;
            }
            if (await showConfirm('Bạn có chắc chắn muốn duyệt không?')) {
                const response = await DataService.patch(
                    state.api.APPROVAL.url + '/' + rows.map((e) => e.id).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Duyệt Bài viết thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async cancelApproval({state}, rows) {
        try {
            if (!rows.length) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không có bản ghi được chọn',
                    duration: 4,
                });
                return false;
            }
            if (await showConfirm('Bạn có chắc chắn muốn bỏ duyệt không?')) {
                const response = await DataService.patch(
                    state.api.CANCEL_APPROVAL.url + '/' + rows.map((e) => e.id).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Bỏ duyệt Bài viết thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async setVisible({state}, visible) {
        state.visible = visible;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
