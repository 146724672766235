import {createStore} from 'vuex'
import themeLayout from './modules/themeLayout/actionCreator'
import auth from './modules/authentication/axios/actionCreator'
import role from './modules/system/role/actionCreator'
import menu from './modules/system/menu/actionCreator'
import user from './modules/system/user/actionCreator'
import chartContent from './modules/chartContent/actionCreator'
import cp from './modules/user/cp/actionCreator'
import cpGroup from './modules/user/cp-group/actionCreator'
import articles from './modules/articles/articles/actionCreator'
import category from './modules/category/actionCreator'
import reportNews from './modules/report/news/actionCreator'
import topUp from './modules/topup/actionCreator'

export default createStore({
    modules: {
        themeLayout,
        auth,
        role,
        menu,
        user,
        chartContent,
        cp,
        cpGroup,
        articles,
        category,
        reportNews,
        topUp
    },
})
