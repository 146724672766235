export const FORM_MODE = {
    CREATE: 1,
    UPDATE: 2,
    VIEW: 3
}

export const GENDERS = [
    {
        value: 1,
        label: 'Nam'
    }, {
        value: 0,
        label: 'Nữ'
    }, {
        value: 2,
        label: 'Khác'
    }
]

export const STATUS = [
    {
        value: 1,
        label: 'Hoạt động'
    }, {
        value: 0,
        label: 'Không hoạt động'
    }
]

export const STATUS_APPROVAL = [
    {
        value: 1,
        label: 'Duyệt'
    }, {
        value: 0,
        label: 'Chưa duyệt'
    }
]

export const TOP_UP_PROVIDER = [
    {
        value: 'VTT',
        label: 'Viettel'
    },
    {
        value: 'VMS',
        label: 'Mobifone'
    },
    {
        value: 'VNP',
        label: 'Vinaphone'
    },
    {
        value: 'VNM',
        label: 'Vietnamobile'
    }
]

export const MONEY_AMOUNT = [
    {
        value: 10000,
        label: '10.000 VNĐ'
    },
    {
        value: 20000,
        label: '20.000 VNĐ'
    },
    {
        value: 50000,
        label: '50.000 VNĐ'
    },
    {
        value: 100000,
        label: '100.000 VNĐ'
    },
    {
        value: 200000,
        label: '200.000 VNĐ'
    },
    {
        value: 500000,
        label: '500.000 VNĐ'
    }
]

export const defaultButton = (menu) => {
    const buttonsForm = [
        {type: 'RESET'},
        {type: 'SEARCH'},
        {type: 'CREATE', permission: `${menu}:create`},
        {type: 'DELETE', permission: `${menu}:delete`},
    ];
    const buttonActions = [
        {type: 'EDIT', permission: `${menu}:edit`},
        {type: 'VIEW'},
    ];
    return {buttonsForm, buttonActions};
};

