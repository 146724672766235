<template>
  <a-row justify="center">
    <a-col :span="24">
      <div class="text-center">
        <cds-button v-if="hasReset" class="sDash_form-action__btn mr-10 mt-1 ml-10" html-type="submit" type="light"
                    size="small"
                    :width="160"
                    @click="emitEvent('onClear')">
          <cds-feather-icons class="mr-5" type="rotate-cw"/> Đặt lại
        </cds-button>
        <cds-button v-if="hasSearch" class="sDash_form-action__btn mr-10 mt-1 ml-10" type="primary" size="small"
                    :width="160"
                    @click="emitEvent('onSearch')">
          <cds-feather-icons class="mr-5" type="search"/>Tìm kiếm
        </cds-button>
        <cds-button v-if="hasCreate" class="sDash_form-action__btn mr-10 mt-1 ml-10" type="primary" size="small"
                    :width="160"
                    @click="emitEvent('onCreate')">
          <cds-feather-icons class="mr-5" type="plus"/> Thêm mới
        </cds-button>
        <cds-button v-if="hasCreateMenu" class="sDash_form-action__btn mr-10 mt-1 mb-10" type="primary" size="small"
                    :width="160"
                    @click="emitEvent('onCreateMenu')">
          <cds-feather-icons class="mr-5" type="plus"/> Thêm mới menu
        </cds-button>
        <cds-button v-if="hasCreateButton" class="sDash_form-action__btn mr-10 mt-1 mb-10" type="primary" size="small"
                    :width="160"
                    @click="emitEvent('onCreateButton')">
          <cds-feather-icons class="mr-5" type="plus"/> Thêm mới nút
        </cds-button>
        <cds-button v-if="hasExportExcel" class="sDash_form-action__btn mr-10 mt-1 mb-10" type="primary" size="small"
                    :width="160"
                    @click="emitEvent('onExportExcel')">
          <cds-feather-icons class="mr-5" type="download"/> Xuất Excel
        </cds-button>
        <cds-button v-if="hasApproval" class="sDash_form-action__btn mr-10 mt-1 mb-10" type="primary" size="small"
                    :width="160"
                    @click="emitEvent('onApproval')">
          <cds-feather-icons class="mr-5" type="check"/> Duyệt
        </cds-button>
        <cds-button v-if="hasCancelApproval" class="sDash_form-action__btn mr-10 mt-1 mb-10" type="danger" size="small"
                    :width="160"
                    @click="emitEvent('onCancelApproval')">
          <cds-feather-icons class="mr-5" type="x"/> Bỏ duyệt
        </cds-button>
        <cds-button v-if="hasDelete" class="sDash_form-action__btn mr-10 mt-1 ml-10" type="danger" size="small"
                    :width="160"
                    @click="emitEvent('onDelete')">
          <cds-feather-icons class="mr-5" type="x"/> Xoá
        </cds-button>
      </div>
    </a-col>
  </a-row>
</template>

<script>
import VueTypes from "vue-types";
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

export default defineComponent({
  name: "SearchFormButton",
  props: {
    buttons: VueTypes.array.def([])
  },
  setup(props, {emit}) {
    const emitEvent = (event) => {
      emit(event);
    }
    const { state } = useStore();
    const permissions = computed(() => state.auth.userInfo.permissions);
    const hasButton = (button) => (props.buttons || []).some(e => e.type === button && (!e.permission || (permissions.value || []).includes(e.permission)));
    return {
      emitEvent,
      hasReset: hasButton('RESET'),
      hasSearch: hasButton('SEARCH'),
      hasCreate: hasButton('CREATE'),
      hasCreateMenu: hasButton('CREATE_MENU'),
      hasCreateButton: hasButton('CREATE_BUTTON'),
      hasDelete: hasButton('DELETE'),
      hasApproval: hasButton('APPROVAL'),
      hasCancelApproval: hasButton('CANCEL_APPROVAL'),
      hasExportExcel: hasButton('EXPORT_EXCEL'),
    };
  },
});
</script>

<style scoped>

</style>
