import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  preCreateMenu(state) {
    state.visibleMenu = true;
    state.formMode = FORM_MODE.CREATE;
    state.menu = {
      menuId: null,
      menuName: '',
      path: '',
      component: '',
      perms: '',
      icon: '',
      orderNum: '',
      parentId: null,
    };
  },

  preCreateButton(state) {
    state.visibleButton = true;
    state.formMode = FORM_MODE.CREATE;
    state.menu = {
      menuId: null,
      menuName: '',
      perms: '',
      parentId: null,
    };
  },
  preChooseIcon(state) {
    state.visibleIcon = true;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  setFormValue(state, { menu, formMode }) {
    state.formMode = formMode;
    if (menu.type === 1) {
      state.visibleButton = true;
    } else {
      state.visibleMenu = true;
    }
    state.menu = {
      ...menu,
    };
  },
};
