import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data.map(e => ({value: e.id, label: e.name}));
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.department = {
      name: '',
      orderNum: '',
      parentId: null,
    };
  },
  setFormValue(state, { department, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.department = {
      ...department,
    };
  },
};
