import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    state.listAll = data.map(e => ({value: e.id, label: e.name}));
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.cp = {
      id: null,
      name: '',
      groupId: null,
      tel: '',
      email: '',
      active: 1
    };
  },
  setFormValue(state, { cp, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.cp = {
      ...cp,
    };
  },
};
