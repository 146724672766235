import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {showConfirm} from '@/util/confirm';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';

const state = () => ({
    list: [],
    loading: false,
    api: ConstantAPI.top_up,
    amountChose: null,
    amountChoseLabel: ''
});

const actions = {
    async setAmount({ state }, amount) {
        state.amountChose = amount.value;
        state.amountChoseLabel = amount.label;
    },
    async topUp({commit, state}, payload) {
        try {
            commit('setLoading', true);
            console.log(payload)
            if (await showConfirm(`Bạn có chắc chắn muốn nạp ${state.amountChoseLabel} và số ${payload.msisdn} không?`)) {
                const response = await DataService.callApi(
                    state.api.TOPUP,
                    {...payload, amount: state.amountChose},
                    null,
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Nạp tiền thành công',
                        duration: 4,
                    });
                    state.amountChose = null
                    state.amountChoseLabel = ''
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
