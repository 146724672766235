<template>
  <a-tree
      v-model:expandedKeys="expandedKeys"
      v-model:selectedKeys="selectedKeys"
      v-model:checkedKeys="checkedKeys"
      checkable
      :checkStrictly="checkStrictly"
      :tree-data="treeData"
      :fieldNames="fieldNames"
      @check="check"
  >
  </a-tree>
</template>

<script>
import { defineComponent, ref } from 'vue';
import VueTypes from "vue-types";

export default defineComponent({
  name: 'Tree',
  props: {
    treeData: VueTypes.array.def([]),
    value: VueTypes.array.def([]),
    checkStrictly: VueTypes.bool.def(true),
    fieldNames: VueTypes.object.def({ children: 'children', title: 'title', key: 'key' })
  },
  setup(props) {
    const expandedKeys = ref([]);
    const selectedKeys = ref([]);
    return {
      expandedKeys,
      selectedKeys,
      checkedKeys: ref({checked: props.value}),
    };
  },
  methods: {
    check() {
      console.log(this.checkedKeys.checked)
      this.$emit('update:value', this.checkedKeys.checked);
    }
  },
  watch: {
    value(val) {
      console.log(val)
      this.checkedKeys = {checked: val};
    }
  }
});
</script>

<style scoped>

</style>
