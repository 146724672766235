<template>
  <template v-if="readOnly">
    {{ valueInput }}
  </template>
  <a-input
      v-else
      ref="inp"
      class="w-100 ant-input-sm"
      v-model:value="valueInput"
      :maxlength="maxlength"
      :show-count="showCount"
      :size="size"
      :iconName="iconName"
      :placeholder="placeholder"
      :allowClear="allowClear"
      :disabled="disabled"
      autoComplete="off"
      @input="onInput"
      @blur="onBlur"
      @press-enter="pressEnter"
  >
    <template v-if="icon" #addonAfter>
      <cds-feather-icons
          :type="icon"
          size="14"
          style="cursor: pointer"
          @click="iconClick(iconName)"
      />
    </template>
  </a-input>
</template>

<script>
import {defineComponent, ref} from 'vue';
import VueTypes from 'vue-types';

export default defineComponent({
  name: 'Input',
  props: {
    value: VueTypes.string.def(''),
    placeholder: VueTypes.string,
    maxlength: VueTypes.oneOfType([VueTypes.string, VueTypes.number]),
    showCount: VueTypes.bool.def(true),
    allowClear: VueTypes.bool.def(true),
    disabled: VueTypes.bool.def(false),
    size: VueTypes.string.def('small'),
    icon: VueTypes.string,
    iconName: VueTypes.string,
    readOnly: VueTypes.bool.def(false),
    firstFocus: VueTypes.bool.def(false),
  },
  emits: ['press-enter'],
  setup(props) {
    const valueInput = ref(props.value);
    return {
      valueInput,
    };
  },
  mounted() {
    if (this.firstFocus)
      setTimeout(() => this.$refs.inp && this.$refs.inp.focus(), 0)
  },
  methods: {
    iconClick() {
      this.$emit('iconClick', this.iconName);
    },
    onInput(e) {
      this.valueInput = e.target.value ? (e.target.value + '').trimLeft() : e.target.value;
      this.$emit('update:value', this.valueInput);
    },
    onBlur() {
      this.$emit('update:value', this.valueInput ? (this.valueInput + '').trim() : this.valueInput);
    },
    pressEnter() {
      this.$emit('press-enter');
    },
  },
  watch: {
    value(val) {
      this.valueInput = val;
    },
  },
});
</script>

<style scoped>
.ant-input-sm {
  padding: 7px 12px;
}
</style>
