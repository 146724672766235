const routes = [
  {
    path: 'login',
    name: 'login',
    component: () =>
      import(
        /* webpackChunkName: "auth" */ '@/view/authentication/SignIn.vue'
      ),
  },
];

export default routes;
