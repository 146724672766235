export const TABLE_LINE_CHART = {
  LAND: 'LAND',
  RECRUITMENT: 'RECRUITMENT',
  CV: 'CV',
  CLASSIFIEDS: 'CLASSIFIEDS',
};

export const LABEL = {
  YEAR: 'Tháng',
  MONTH: 'Ngày',
};

export const PRE_LABEL = {
  DAY: 'Hôm qua',
  WEEK: 'Tuần trước',
  MONTH: 'Tháng trước',
  YEAR: 'Năm trước',
};

export const DAY_OF_WEEK = [
  'Chủ nhật',
  'Thứ 2',
  'Thứ 3',
  'Thứ 4',
  'Thứ 5',
  'Thứ 6',
  'Thứ 7',
];
