import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { FORM_MODE } from '@/util/common-constant';

const state = () => ({
  list: [],
  loading: false,
  visibleMenu: false,
  visibleButton: false,
  visibleIcon: false,
  api: ConstantAPI.menu,
});

const actions = {
  async getAll({ commit, state }) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.SEARCH);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
      commit('setLoading', false);
    }
  },
  async preView({ commit }, row) {
    commit('setFormValue', {
      menu: row,
      formMode: FORM_MODE.VIEW,
    });
  },
  async preUpdate({ commit }, row) {
    commit('setFormValue', {
      menu: row,
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preEditMenu({ commit }) {
    commit('preEditMenu');
  },
  async preCreateMenu({ commit }) {
    commit('preCreateMenu');
  },
  async preCreateButton({ commit }) {
    commit('preCreateButton');
  },
  async preChooseIcon({ commit }) {
    commit('preChooseIcon');
  },
  async setVisibleMenu({ state }, visible) {
    state.visibleMenu = visible;
  },
  async setVisibleButton({ state }, visible) {
    state.visibleButton = visible;
  },
  async setVisibleIcon({ state }, visible) {
    state.visibleIcon = visible;
  },
  async createMenu({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        const response = await DataService.callApi(
          state.api.CREATE,
          payload,
          null,
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Menu thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async updateMenu({ commit, state }, payload) {
    try {
      commit('setLoading', true);
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        const response = await DataService.callApi(
          state.api.UPDATE,
          {
            ...payload,
            menuId: state.menu.menuId,
          },
          null,
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật Menu thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async delete({ state }, rows) {
    console.log(rows);
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e && e.menuId).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa menu thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
