export default {
  report_service: {
    OVERVIEW: {
      url: '/overview',
      method: 'GET',
    },
    OVERVIEW_LINE: {
      url: '/chart',
      method: 'GET',
    },
  },
  role: {
    GET_ALL: {
      url: '/role/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: '/role',
      method: 'GET',
    },
    CREATE: {
      url: '/role',
      method: 'POST',
    },
    UPDATE: {
      url: '/role',
      method: 'PUT',
    },
    DELETE: {
      url: '/role',
      method: 'DELETE',
    },
  },
  role_menu: {
    GET_BY_ROLE: {
      url: '/role-menu',
      method: 'GET',
    },
  },
  user_role: {
    GET_BY_USER: {
      url: '/user-role',
      method: 'GET',
    },
  },
  articles: {
    ALL: {
      url: '/article/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: '/article',
      method: 'GET',
    },
    CREATE: {
      url: '/article',
      method: 'POST',
    },
    UPDATE: {
      url: '/article',
      method: 'PUT',
    },
    DELETE: {
      url: '/article',
      method: 'DELETE',
    },
    APPROVAL: {
      url: '/article/approve',
      method: 'DELETE',
    },
    CANCEL_APPROVAL: {
      url: '/article/not-approve',
      method: 'DELETE',
    },
    TOP: {
      url: '/article/on-top',
      method: 'DELETE',
    },
  },
  cp: {
    ALL: {
      url: '/cp/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: '/cp',
      method: 'GET',
    },
    CREATE: {
      url: '/cp',
      method: 'POST',
    },
    UPDATE: {
      url: '/cp',
      method: 'PUT',
    },
    DELETE: {
      url: '/cp',
      method: 'DELETE',
    },
  },
  cpGroup: {
    ALL: {
      url: '/cp-group/get-all',
      method: 'GET',
    }
  },
  category: {
    ALL: {
      url: '/category/get-all',
      method: 'GET',
    },
    SEARCH: {
      url: '/category',
      method: 'GET',
    },
    CREATE: {
      url: '/category',
      method: 'POST',
    },
    UPDATE: {
      url: '/category',
      method: 'PUT',
    },
    DELETE: {
      url: '/category',
      method: 'DELETE',
    },
  },
  menu: {
    SEARCH: {
      url: '/menu',
      method: 'GET',
    },
    CREATE: {
      url: '/menu',
      method: 'POST',
    },
    UPDATE: {
      url: '/menu',
      method: 'PUT',
    },
    DELETE: {
      url: '/menu',
      method: 'DELETE',
    },
  },
  user: {
    SEARCH: {
      url: '/user',
      method: 'GET',
    },
    CREATE: {
      url: '/user',
      method: 'POST',
    },
    UPDATE: {
      url: '/user',
      method: 'PUT',
    },
    DELETE: {
      url: '/user',
      method: 'DELETE',
    },
    CHECK_USERNAME_EXISTED: {
      url: '/user/check',
      method: 'GET',
    },
    GET_USER_INFO: {
      url: '/user/get-user-info',
      method: 'GET',
    },
    PATCH_UPDATE: {
      url: '/user',
      method: 'PATCH',
    },
  },
  auth: {
    LOGIN: {
      url: '/oauth/token',
      method: 'POST',
    },
    LOGOUT: {
      url: '/oauth/token/revoke',
      method: 'DELETE',
    },
    PATCH_UPDATE: {
      url: '/user',
      method: 'PATCH',
    },
  },
  upload_service: {
    UPLOAD: {
      url: '/upload',
      method: 'POST',
    },
  },
  report: {
    LOG: {
      url: '/report/log',
      method: 'GET',
    },
    STATISTICAL_FILE: {
      url: '/report/statistical-file',
      method: 'GET',
    },
    STATISTICAL: {
      url: '/report/statistical',
      method: 'GET',
    }
  },
  top_up: {
    TOPUP: {
      url: '/top-up',
      method: 'POST',
    }
  },
};
