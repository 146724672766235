<template>
  <div>
    <div>
      <a-table
        class="mb-25"
        :columns="tableColumns"
        :data-source="data"
        :pagination="clientPagination"
        :tableLayout="tableLayout"
        :showSorterTooltip="showSorterTooltip"
        :loading="loading"
        :rowSelection="rowSelection"
        :scroll="scroll"
        @resizeColumn="handleResizeColumn"
      >
        <template v-if="title" #title>{{ title }}</template>
        <template #headerCell="{ column }">
          <slot name="custom-header" :column="column">
            <div class="text-center">{{ column.title }}</div>
          </slot>
        </template>
        <template #bodyCell="{ column, record }">
          <slot name="custom-body" :column="column" :record="record"></slot>
        </template>
        <slot name="custom-column"></slot>
      </a-table>
      <a-pagination
        v-if="pagination && total"
        style="float: right"
        :show-size-changer="showSizeChanger"
        v-model:current="page"
        v-model:page-size="size"
        :total="total"
        :page-size-options="pageSizeOptions"
        show-quick-jumper
        @change="fetchData(false)"
        @show-size-change="sizeChange"
      />
    </div>
  </div>
</template>

<script src="./table-controller.js"></script>
