import mutations from './mutations'
import ConstantAPI from '@/config/ConstantAPI'
import {DataService} from '@/dataService/dataService'
import {getField, updateField} from 'vuex-map-fields'

const state = () => ({
  list: [],
  loading: false,
  cp: {},
  api: ConstantAPI.cpGroup,
  visible: false,
})

const actions = {
  async getAll ({commit, state}) {
    try {
      const response = await DataService.callApi(state.api.ALL)
      return commit('getAllSuccess', response.data)
    } catch (err) {
      console.log(err)
    }
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
}
