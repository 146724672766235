import store from '@/vuex/store';


const guard = (from) => {
    if (store.getters.allMenu.includes(from.path)) {
        return true
    }
    return { path: '404' }
};

const adminRoutes = [
    {
        path: '',
        name: 'dashboard',
        component: () =>
            import(
                /* webpackChunkName: "Dashboard" */ '@/view/dashboard/Dashboard.vue'
                ),
        children: [
            {
                path: '',
                name: 'home',
                component: () =>
                    import(/* webpackChunkName: "Dashboard" */ '@/view/dashboard'),
            },
        ],
    },
    {
        path: 'settings',
        name: 'settings',
        component: () =>
            import(
                /* webpackChunkName: "Settings" */ '@/view/pages/settings/Settings.vue'
                ),
        children: [
            {
                path: 'profile-settings',
                name: 'profile-settings',
                component: () =>
                    import(
                        /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
                        ),
            },
            {
                path: 'profile-settings/profile',
                name: 'set-profile',
                component: () =>
                    import(
                        /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Profile.vue'
                        ),
            },
            {
                path: 'profile-settings/account',
                name: 'set-account',
                component: () =>
                    import(
                        /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Account.vue'
                        ),
            },
            {
                path: 'profile-settings/password',
                name: 'set-password',
                component: () =>
                    import(
                        /* webpackChunkName: "Settings" */ '@/view/pages/settings/overview/Password.vue'
                        ),
            },
        ],
    },
    {
        path: 'manager',
        name: 'user-management',
        children: [
            {
                path: 'user',
                name: 'user-management',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "Manager" */ '@/view/manager/user/user/User.vue'
                        ),
            },
            {
                path: 'cp',
                name: 'cp-management',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "Manager" */ '@/view/manager/user/cp/Cp.vue'
                        ),
            },
        ],
    },
    {
        path: 'article-manager',
        name: 'article-management',
        children: [
            {
                path: 'upload',
                name: 'article-management',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "Manager" */ '@/view/manager/article/article/Article.vue'
                        ),
            },
            {
                path: 'approval',
                name: 'approval-clip',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "Manager" */ '@/view/manager/article/approval/Approval.vue'
                        ),
            },
        ],
    },
    {
        path: 'category',
        name: 'category-management',
        beforeEnter: [guard],
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/manager/category/Category.vue'
                ),
    },
    {
        path: 'report',
        name: 'report',
        children: [
            {
                path: 'logs',
                name: 'logs',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "report" */ '@/view/report/logs/Logs.vue'
                        ),
            },
            {
                path: 'news',
                name: 'report',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "report" */ '@/view/report/news/News.vue'
                        ),
            },
        ],
    },
    {
        path: 'system',
        name: 'system',
        children: [
            {
                path: 'menu',
                name: 'menu-management',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "Manager" */ '@/view/system/menu/Menu.vue'
                        ),
            },
            {
                path: 'role',
                name: 'role-management',
                beforeEnter: [guard],
                component: () =>
                    import(
                        /* webpackChunkName: "Manager" */ '@/view/system/role/Role.vue'
                        ),
            },
        ],
    },
    {
        path: 'top-up',
        name: 'top-up',
        beforeEnter: [guard],
        component: () =>
            import(
                /* webpackChunkName: "Manager" */ '@/view/topup/TopUp.vue'
                ),
    }
];

export default adminRoutes;
