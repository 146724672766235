import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    state.listAll = data.map(e => ({value: e.id, label: e.name}));
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.articles = {
      id: null,
      accepteduserId: null,
      anecode: null,
      categoryId: null,
      publishTime: null,
      content: "",
      hot: 0,
      thumbPath: [],
      intro: '',
      status: 1,
      title: '',
      view: 0,
      source: '',
    };
  },
  setFormValue(state, { articles, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.articles = {
      ...articles,
      thumbPath: [articles.thumbPath]
    };
  },
};
