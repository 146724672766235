import { FORM_MODE } from '@/util/common-constant';

export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  getAllSuccess(state, data) {
    state.list = data;
    state.loading = false;
  },
  getAll(state, data) {
    state.listAll = data;
    state.loading = false;
  },
  preCreate(state) {
    state.visible = true;
    state.formMode = FORM_MODE.CREATE;
    state.category = {
      id: null,
      title: '',
      isActive: 1,
      order: ''
    };
  },
  setFormValue(state, { category, formMode }) {
    state.visible = true;
    state.formMode = formMode;
    state.category = {
      ...category,
    };
  },
};
