import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {FORM_MODE} from '@/util/common-constant';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {showConfirm} from '@/util/confirm';

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    category: {},
    api: ConstantAPI.category,
    visible: false,
});

const actions = {
    async getSearch({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.SEARCH);
            return commit('getAllSuccess', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async getAll({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.ALL);
            return commit('getAll', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async preCreate({commit}) {
        commit('preCreate');
    },
    async preUpdate({commit}, row) {
        commit('setFormValue', {
            category: row,
            formMode: FORM_MODE.UPDATE,
        });
    },
    async preView({commit}, row) {
        commit('setFormValue', {
            category: row,
            formMode: FORM_MODE.VIEW,
        });
    },
    async create({commit, state}, payload) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(
                state.api.CREATE,
                payload,
                null,
            );
            let success = false;
            checkResponse(response, () => {
                success = true;
                notification.success({
                    message: 'Thông báo',
                    description: 'Thêm mới Chuyên mục thành công',
                    duration: 4,
                });
            });
            return success;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async update({commit, state}, payload) {
        try {
            commit('setLoading', true);
            const response = await DataService.callApi(
                state.api.UPDATE,
                {
                    ...payload,
                    id: state.category.id,
                },
                null,
            );
            let success = false;
            checkResponse(response, () => {
                success = true;
                notification.success({
                    message: 'Thông báo',
                    description: 'Cập nhật Chuyên mục thành công',
                    duration: 4,
                });
            });
            return success;
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async delete({state}, rows) {
        try {
            if (!rows.length) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không có bản ghi được chọn',
                    duration: 4,
                });
                return false;
            }
            if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
                const response = await DataService.delete(
                    state.api.DELETE.url + '/' + rows.map((e) => e.id).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Xóa Chuyên mục thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async setVisible({state}, visible) {
        state.visible = visible;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
