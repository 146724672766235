import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {notification} from 'ant-design-vue';

const state = () => ({
    list: [],
    listAll: [],
    loading: false,
    articles: {},
    api: ConstantAPI.report,
    visible: false,
});

const actions = {
    async download({commit, state}, param) {
        try {
            commit('setLoading', true);
            notification.info({
                message: 'Thông báo',
                description: 'Đang tiến hành tải file',
                duration: 4,
            });
            const response = await DataService.getConfig(
                state.api.STATISTICAL_FILE.url,
                param,
                null,
                'blob'
            );
            const url = window.URL.createObjectURL(new Blob([response]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', 'thong_ke.xlsx')
            document.body.appendChild(link)
            link.click()
            notification.success({
                message: 'Thông báo',
                description: 'Thành công',
                duration: 4,
            });
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async getAll({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.ALL);
            return commit('getAll', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async setVisible({state}, visible) {
        state.visible = visible;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
