import { Modal } from 'ant-design-vue';

export function showConfirm(message, okText, cancelText) {
  return new Promise((resolve) => {
    Modal.confirm({
      title: 'Xác nhận',
      content: message,
      okText: okText || 'Có',
      cancelText: cancelText || 'Không',
      onOk: () => resolve(true),
      onCancel: () => resolve(false),
    });
  });
}
