import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import { getField, updateField } from 'vuex-map-fields';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { FORM_MODE } from '@/util/common-constant';
import { showConfirm } from '@/util/confirm';

const state = () => ({
  loading: false,
  visible: false,
  formMode: FORM_MODE.CREATE,
  api: ConstantAPI.role,
  list: [],
  role: null,
});

const actions = {
  async setVisible({ state }, visible) {
    state.visible = visible;
  },
  async preCreate({ commit }) {
    commit('preCreate');
  },
  async preUpdate({ commit }, row) {
    const response = await DataService.callApi(
      ConstantAPI.role_menu.GET_BY_ROLE,
      null,
      { roleId: row.roleId }
    );
    commit('setFormValue', {
      role: { ...row, roleMenus: (response.data || []).map((e) => e.menuId) },
      formMode: FORM_MODE.UPDATE,
    });
  },
  async preView({ commit }, row) {
    const response = await DataService.callApi(
      ConstantAPI.role_menu.GET_BY_ROLE,
      null,
      { roleId: row.roleId }
    );
    commit('setFormValue', {
      role: { ...row, roleMenus: (response.data || []).map((e) => e.menuId) },
      formMode: FORM_MODE.VIEW,
    });
  },
  async create({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.CREATE,
          payload,
          null,
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Thêm mới Quyền thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async update({ commit, state }, payload) {
    try {
      if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
        commit('setLoading', true);
        const response = await DataService.callApi(
          state.api.UPDATE,
          {
            ...payload,
            roleId: state.role.roleId,
          },
          null,
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Cập nhật Quyền thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    } finally {
      commit('setLoading', false);
    }
  },
  async delete({ state }, rows) {
    try {
      if (!rows.length) {
        notification.error({
          message: 'Thông báo',
          description: 'Không có bản ghi được chọn',
          duration: 4,
        });
        return false;
      }
      if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
        const response = await DataService.delete(
          state.api.DELETE.url + '/' + rows.map((e) => e.roleId).join(',')
        );
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: 'Xóa Quyền thành công',
            duration: 4,
          });
        });
        return success;
      }
    } catch (err) {
      console.log(err);
      return false;
    }
  },
  async getAll({ commit, state }) {
    try {
      const response = await DataService.callApi(state.api.GET_ALL);
      return commit('getAllSuccess', response.data);
    } catch (err) {
      console.log(err);
    }
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
