import Cookies from "js-cookie";
import { flatten } from "@/util/common-utils";

export default {
  loginBegin(state) {
    state.loading = true;
  },
  setTokenInfo(state, tokenInfo) {
    Cookies.set('tokenInfo', JSON.stringify(tokenInfo));
    state.tokenInfo = tokenInfo;
  },
  getUserInfoSuccess(state, data) {
    data.allMenu = flatten(data.menus || [], 'path')
    state.loading = false;
    localStorage.setItem('allMenu', JSON.stringify(data.allMenu || []));
    state.userInfo = data;
    state.error = null;
  },
  loginErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  logoutSuccess(state) {
    state.loading = false;
    state.tokenInfo = null;
    state.userInfo = null;
  },

  logoutErr(state, err) {
    state.loading = false;
    state.error = err;
  },

  routerLoaded(state) {
    state.routerLoaded = true;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },

  setUpLoading(state, loading) {
    state.uploading = loading;
  },
};
