import Styled from "vue3-styled-components";

const DatePickerWrapper = Styled.div`
    .ant-calendar-picker{
        width: 100% !important;
        border-color: #E3E6EF;
        border-radius: 5px;
        .ant-calendar-picker-input{
            padding: 6px 11px 6px;
        }
    }
    .ant-calendar-picker:not(:last-child){
        margin-bottom: 20px;
    }
    .ant-calendar-picker > input{
        color: #5A5F7D;
    }
    .ant-picker-range .ant-picker-input > input{
        text-align: center;
        font-weight: 500;
    }
    
`;
export {DatePickerWrapper};