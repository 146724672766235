import mutations from './mutations';
import ConstantAPI from '@/config/ConstantAPI';
import {DataService} from '@/dataService/dataService';
import {getField, updateField} from 'vuex-map-fields';
import {FORM_MODE} from '@/util/common-constant';
import {checkResponse} from '@/util/common-utils';
import {notification} from 'ant-design-vue';
import {showConfirm} from '@/util/confirm';

const state = () => ({
    list: [],
    loading: false,
    user: {},
    api: ConstantAPI.user,
    visible: false,
});

const actions = {
    async getAll({commit, state}) {
        try {
            const response = await DataService.callApi(state.api.SEARCH);
            return commit('getAllSuccess', response.data);
        } catch (err) {
            console.log(err);
        }
    },
    async preCreate({commit}) {
        commit('preCreate');
    },
    async preUpdate({commit}, row) {
        const response = await DataService.callApi(
            ConstantAPI.user_role.GET_BY_USER,
            null,
            {userId: row.userId}
        );
        commit('setFormValue', {
            user: {...row, userRoles: (response.data || []).map((e) => e.roleId)},
            formMode: FORM_MODE.UPDATE,
        });
    },
    async preView({commit}, row) {
        const response = await DataService.callApi(
            ConstantAPI.user_role.GET_BY_USER,
            null,
            {userId: row.userId}
        );
        commit('setFormValue', {
            user: {...row, userRoles: (response.data || []).map((e) => e.roleId)},
            formMode: FORM_MODE.VIEW,
        });
    },
    async create({commit, state}, payload) {
        try {
            if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
                commit('setLoading', true);
                const data = {
                    ...payload,
                    userRoles: payload.userRoles.map((e) => ({roleId: e})),
                };
                const response = await DataService.callApi(
                    state.api.CREATE,
                    data,
                    null,
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Thêm mới Người dùng thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async update({commit, state}, payload) {
        try {
            if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
                commit('setLoading', true);
                const data = {
                    ...payload,
                    userRoles: (payload.userRoles || []).map((e) => ({roleId: e})),
                    userId: state.user.userId,
                };
                const response = await DataService.callApi(
                    state.api.UPDATE,
                    data,
                    null,
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Cập nhật Người dùng thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        } finally {
            commit('setLoading', false);
        }
    },
    async delete({state}, rows) {
        try {
            if (!rows.length) {
                notification.error({
                    message: 'Thông báo',
                    description: 'Không có bản ghi được chọn',
                    duration: 4,
                });
                return false;
            }
            if (await showConfirm('Bạn có chắc chắn muốn xóa không?')) {
                const response = await DataService.delete(
                    state.api.DELETE.url + '/' + rows.map((e) => e.userId).join(',')
                );
                let success = false;
                checkResponse(response, () => {
                    success = true;
                    notification.success({
                        message: 'Thông báo',
                        description: 'Xóa Người dùng thành công',
                        duration: 4,
                    });
                });
                return success;
            }
        } catch (err) {
            console.log(err);
            return false;
        }
    },
    async setVisible({state}, visible) {
        state.visible = visible;
    },
};

export default {
    namespaced: true,
    state,
    actions,
    mutations: {
        ...mutations,
        updateField,
    },
    getters: {
        getField,
    },
};
