/**
 * Return ellipsis of a given string
 * @param {string} text
 * @param {number} size
 */
import { JSEncrypt } from 'jsencrypt';
import { PUBLIC_KEY, SECRET } from '@/config/key';
import CryptoJS from 'crypto-js';
import moment from 'moment/moment';
import { notification } from 'ant-design-vue';
import dayjs from 'dayjs';
import _ from 'lodash';

const ellipsis = (text, size) => {
  return `${text.split(' ').slice(0, size).join(' ')}...`;
};

const encryptData = (data, publicKey = PUBLIC_KEY) => {
  let encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  return encryptor.encrypt(data);
};

const hashData = (payload, secret = SECRET) => {
  return CryptoJS.SHA256(JSON.stringify(payload) + secret).toString();
};

const formatDate = (date, format) => {
  if (_.isNil(date)) return '';
  return moment(date).format(format || 'DD/MM/YYYY HH:mm:ss');
};

const checkResponse = (
  response,
  success = () => {},
  fail = () => {
    notification.error({
      message: 'Thông báo',
      description: response.message || 'Lỗi',
      duration: 4,
    });
  }
) => {
  if (response.code === '200') {
    success(response.data);
  } else {
    fail(response.data);
  }
};

const forEachNested = (parent, cb) => {
  if (!cb) return;
  cb(parent);
  if (parent.children && parent.children.length > 0) {
    parent.children.forEach((p) => forEachNested(p, cb));
  }
};

const greaterThanCurrent = (current) =>
  current && current > dayjs().endOf('day');

const lessThanCurrent = (current) =>
  current && current <= dayjs().endOf('day').subtract(1, 'days');

const getText = (value, array) => {
  const s = array.filter((s) => s.value === value);
  if (s && s.length) return s[0].label;
  return '';
};

const censorNumber = (value) => {
  return value.substring(0, 3) + '****' + value.substring(7, value.length - 7);
};

const roundNumber = (number, digit = 2) => {
  if (_.isNil(number)) return null;
  return parseFloat(number.toFixed(digit));
};

const flatten = (array, field) => {
  return array.reduce((newArr, obj) => {
    if (field) {
      newArr.push(obj[field]);
    } else {
      newArr.push(obj);
    }
    if (obj.children) {
      return newArr.concat(flatten(obj.children, field));
    }
    return newArr;
  }, []);
}

export {
  ellipsis,
  encryptData,
  hashData,
  formatDate,
  checkResponse,
  forEachNested,
  greaterThanCurrent,
  lessThanCurrent,
  getText,
  censorNumber,
  roundNumber,
  flatten,
};
