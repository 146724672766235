<template>
  <div class="align-center">
      <a-tooltip title="Sửa" v-if="hasEdit">
        <a-button type="ghost" :disabled="disabledEdit" style="color: #2c99ff" shape="circle" class="mr-5"
                  @click="emitEvent('onEdit')">
          <template #icon>
            <cds-feather-icons type="edit-3"/>
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip title="Xem" v-if="hasView">
        <a-button type="ghost" style="color: #20C997" shape="circle" @click="emitEvent('onView')">
          <template #icon>
            <cds-feather-icons type="eye"/>
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip title="Duyệt" v-if="hasApproval && !isShowApproval">
        <a-button type="ghost" class="mr-5" style="color: #20C997" shape="circle" @click="emitEvent('onApproval')">
          <template #icon>
            <cds-feather-icons type="check"/>
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip title="Hủy" v-if="hasCancelApproval && isShowApproval">
        <a-button type="ghost" class="mr-5" style="color: #FF4D4F" shape="circle" @click="emitEvent('onCancelApproval')">
          <template #icon>
            <cds-feather-icons type="x"/>
          </template>
        </a-button>
      </a-tooltip>
      <a-tooltip title="TOP" v-if="hasOnTop">
        <a-button type="ghost" style="color: #20C997" shape="circle" @click="emitEvent('onTop')">
          <template #icon>
            <cds-feather-icons type="arrow-up"/>
          </template>
        </a-button>
      </a-tooltip>
  </div>
</template>

<script>
import VueTypes from "vue-types";
import {computed, defineComponent} from "vue";
import {useStore} from "vuex";

export default defineComponent({
  name: "ActionButton",
  props: {
    // {type, permission}
    buttons: VueTypes.array.def([]),
    disabledEdit: VueTypes.bool.def(false),
    isShowApproval: VueTypes.bool.def(false)
  },
  setup(props, {emit}) {
    const emitEvent = (event) => {
      emit(event);
    }
    const {state} = useStore();
    const permissions = computed(() => state.auth.userInfo.permissions || []);
    const hasButton = (button) => props.buttons.some(e => e.type === button && (!e.permission || permissions.value.includes(e.permission)));
    return {
      emitEvent,
      hasEdit: hasButton('EDIT'),
      hasView: hasButton('VIEW'),
      hasDelete: hasButton('DELETE'),
      hasOnTop: hasButton('ON-TOP'),
      hasApproval: hasButton('APPROVAL'),
      hasCancelApproval: hasButton('CANCEL_APPROVAL')
    };
  },
});
</script>

<style scoped>

</style>
