import mutations from './mutations'
import Cookies from 'js-cookie'
import { checkResponse } from '@/util/common-utils'
import router from '@/routes/protectedRoute'
import { DataService } from '@/dataService/dataService'
import { showConfirm } from '@/util/confirm'
import { notification } from 'ant-design-vue'
import ConstantAPI from '@/config/ConstantAPI'

const state = () => ({
    tokenInfo: JSON.parse(Cookies.get('tokenInfo') || null),
    userInfo: { allMenu: JSON.parse(localStorage.getItem('allMenu') || null) },
    loading: false,
    uploading: false,
    error: null,
    routerLoaded: false,
    api: ConstantAPI.auth,
})

const actions = {
    async login({ commit, state }, data) {
        try {
            commit('loginBegin', true)
            const payload = {
                ...data,
                grant_type: "password",
            }
            let response = await DataService.callApi(
                state.api.LOGIN,
                new URLSearchParams(payload),
                null,
                { 'Content-Type': 'application/x-www-form-urlencoded' }
            )
            checkResponse(
                response,
                async () => {
                    commit('setTokenInfo', response.data)
                    response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO)
                    router.push('/')
                    return commit('getUserInfoSuccess', response.data)
                },
                () => {
                    console.log(response)
                    commit('loginErr', response.message)
                }
            )
        } catch (err) {
            console.log(err)
            commit('loginErr', "Username hoặc mật khẩu không đúng")
        }
    },
    async logOut({ state, commit }) {
        try {
            if (state.tokenInfo && state.tokenInfo.access_token) {
                Cookies.remove('tokenInfo')
                localStorage.removeItem('allMenu')
            }
            router.push('/auth/login')
            commit('logoutSuccess')
        } catch (err) {
            commit('logoutErr', err)
        }
    },
    async getUserInfo({ commit }) {
        const response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO)
        commit('getUserInfoSuccess', response.data)
    },
    async changePassword({ commit, state }, payload) {
        try {
            if (await showConfirm('Bạn có chắc chắn muốn đổi mật khẩu không?')) {
                commit('setLoading', true)
                const data = {
                    userId: state.userInfo.userId,
                    newPassword: payload.newPassword,
                    oldPassword: payload.oldPassword,
                }
                const response = await DataService.callApi(
                    state.api.PATCH_UPDATE,
                    data,
                )
                checkResponse(response, () => {
                    notification.success({
                        message: 'Thông báo',
                        description: 'Thay đổi mật khẩu thành công',
                        duration: 4,
                    })
                })
            }
        } catch (err) {
            console.log(err)
            return false
        } finally {
            commit('setLoading', false)
        }
    },
    async updateProfile({ commit }, payload) {
        try {
            if (await showConfirm('Bạn có chắc chắn muốn lưu không?')) {
                commit('setLoading', true)
                payload.userROles = null
                const response = await DataService.callApi(
                    ConstantAPI.user.UPDATE,
                    payload,
                    null,
                )
                checkResponse(response, async () => {
                    notification.success({
                        message: 'Thông báo',
                        description: 'Cập nhật thông tin thành công',
                        duration: 4,
                    })
                    const response = await DataService.callApi(ConstantAPI.user.GET_USER_INFO)
                    commit('getUserInfoSuccess', response.data)
                })
            }
        } catch (err) {
            console.log(err)
            return false
        } finally {
            commit('setLoading', false)
        }
    },
    async changeAvatar({ commit, state }, file) {
        try {
            commit('setUpLoading', true)
            const formData = new FormData()
            formData.append('file', file)
            formData.append('rootPath', 'avatar')
            let response = await DataService.callApi(
                ConstantAPI.upload_service.UPLOAD,
                formData,
                null,
                { 'Content-Type': 'multipart/form-data' }
            )
            checkResponse(response, async () => {
                response = await DataService.callApi(
                    ConstantAPI.user.PATCH_UPDATE,
                    { userId: state.userInfo.userId, avatar: response.data.url },
                )
                checkResponse(response, async () => {
                    notification.success({
                        message: 'Thông báo',
                        description: 'Thay đổi avatar thành công',
                        duration: 4,
                    })
                    const response = await DataService.get(ConstantAPI.user.GET_USER_INFO)
                    commit('getUserInfoSuccess', response.data)
                })
            })
        } catch (err) {
            console.log(err)
            return false
        } finally {
            commit('setUpLoading', false)
        }
    },
}

export default {
    namespaced: false,
    state,
    actions,
    mutations,
    getters: {
        accessToken: (state) =>
            state.tokenInfo ? state.tokenInfo.access_token : null,
        menus: (state) => (state.userInfo ? state.userInfo.menus : []),
        allMenu: (state) => (state.userInfo || {}).allMenu || [],
        loading: (state) => state.loading,
        uploading: (state) => state.uploading,
    },
}
